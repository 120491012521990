<template>
  <section class="comment-wrap" ref="commentWrap">
    <ul class="comment-lists" v-if="commentObject.list.length > 0">
      <li class="comment-item" v-for="(item, index) in commentObject.list" :key="item.id">
        <section class="item-left">
          <section class="photo-wrap">
            <img :src="item.user_avatar | removeProtocolHeader" alt="" />
          </section>
        </section>
        <section class="item-right">
          <section class="item-right-up">
            <p class="name">{{ item.user_nickname }}</p>
            <p class="date">{{ item.create_time | timestampSerialize }}</p>
            <p class="reply">
              <span class="reply-first">回复</span>
              <span class="myself">{{ userInfo.nickname }}</span>
              <span v-html="formatEmojiBr('：' + item.reply)"></span>
            </p>
            <p
              class="reply-btn"
              v-if="talkObject[item.talk_id] !== 404"
              @click.stop="
                replyBtn({
                  talk_id: item.talk_id,
                  comment_id: item.id,
                  index: index
                })
              "
            >
              <i></i>
              <span>回复</span>
            </p>
          </section>
          <section class="item-right-down" v-if="talkObject[item.talk_id] !== 404">
            <section class="down-left">
              <section class="down-photo-wrap">
                <img :src="userInfo.avatar | removeProtocolHeader" alt="" />
              </section>
            </section>
            <section class="down-right">
              <section class="down-right-up">
                <p class="name">{{ userInfo.nickname }}</p>
                <p class="reply" v-html="formatEmojiBr(item.content)"></p>
              </section>
              <section class="down-right-down" :class="talkObject[item.talk_id] ? '' : 'no-talk'">
                <section class="down-photo-wrap">
                  <img :src="(talkObject[item.talk_id] ? talkObject[item.talk_id].img169 : '') | removeProtocolHeader" alt="" />
                </section>
                <section class="content" @click="openTalk(item.talk_id)">
                  <p class="title">
                    {{ talkObject[item.talk_id] ? talkObject[item.talk_id].zt_name : '' }}
                  </p>
                  <p class="text">
                    {{ talkObject[item.talk_id] ? talkObject[item.talk_id].zt_desc : '' }}
                  </p>
                </section>
              </section>
            </section>
          </section>
          <section class="item-404" v-else>
            <p class="tips">
              <span class="icon"></span>
              <span class="text">抱歉，此话题已被删除</span>
            </p>
          </section>

          <section class="comment-textarea-wrap" v-if="item.replyEmojiIsShow">
            <!-- 关闭 -->
            <section class="close-btn" @click="item.replyEmojiIsShow = false"></section>

            <!-- textarea -->
            <el-input type="textarea" class="comment-textarea" :rows="3" :placeholder="replyObject.placeholder" v-model="replyObject.content" ref="commentTextarea"></el-input>

            <section class="textarea-bar">
              <!-- 表情 -->
              <section class="emoji-btn" @click="commentEmojiIsShow = !commentEmojiIsShow">
                <span class="emoji-icon-png"></span>
                <span class="emoji-text">表情</span>
              </section>

              <!-- 回复 -->
              <section class="reply-btn">
                <el-button type="primary" @click="replyRequest">回复</el-button>
              </section>
            </section>

            <!-- emoji 表情列表 -->
            <section class="emoji-icon" v-if="commentEmojiIsShow">
              <ul class="emoji-icon-list">
                <li class="emoji-icon-item" v-for="item in emojiList" :key="item.title" @click="commentEmojiClick(item.title)">
                  <img :src="item.src" :alt="item.title" :title="item.title" />
                </li>
              </ul>
            </section>
          </section>
        </section>
      </li>
    </ul>

    <section class="not-comment" v-else>
      <img src="../../assets/image/not-comment.png" alt="" />
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import emojiPngList from '@/util/emoji_png.js';
import { replyList, readMessage, axiosAddComment } from '@/api/axios.request';
import { getTopicData } from '@/api/detail.js';
import { timestampSerialize, formatEmojiBr, removeProtocolHeader } from '@/util/methods.js';

export default {
  name: 'comment',
  data() {
    return {
      emojiList: emojiPngList, // emoji 表情
      id: null,
      // 评论列表
      commentObject: {
        cursor: false,
        isEnd: false,
        scrollFlag: false,
        list: []
      },
      // 话题 id 详情
      talkObject: {},
      // 回复 emoji 显示
      commentEmojiIsShow: false,
      // 回复
      replyObject: {
        index: '',
        talk_id: '',
        comment_id: '',
        placeholder: '说两句吧...',
        content: ''
      }
    };
  },
  computed: {
    ...mapState(['userInfo', 'loginStatus', 'notificationNum'])
  },
  filters: { timestampSerialize, removeProtocolHeader },
  watch: {
    loginStatus: {
      handler(curVal) {
        if (curVal) {
          this.scrollEvent();
        } else {
          this.commentObject = {
            cursor: false,
            isEnd: false,
            scrollFlag: false,
            list: []
          };
        }
      }
    },
    commentObject: {
      handler(curVal) {
        let tempTalkObject = {};

        curVal.list.forEach((element) => {
          if (!this.talkObject[element.talk_id]) {
            tempTalkObject[element.talk_id] = false;
          } else {
            tempTalkObject[element.talk_id] = this.talkObject[element.talk_id];
          }
        });

        this.talkObject = tempTalkObject;
      },
      deep: true,
      immediate: true
    },
    talkObject: {
      handler(curVal) {
        for (const key in curVal) {
          if (!curVal[key]) {
            this.getDetail(key);
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    formatEmojiBr,
    // 获取评论我的列表
    getReplyList() {
      let _this = this;

      let { cursor, isEnd } = _this.commentObject;

      if (isEnd) {
        return;
      }

      let params = {
        page_size: 15
      };

      if (cursor) {
        params.cursor = cursor;
      }

      replyList(params).then((response) => {
        const { data } = response;

        if (data.code === 200) {
          let tempData = data.result.list;

          tempData.forEach((element) => {
            element.replyEmojiIsShow = false;
          });

          tempData.sort((a, b) => {
            return new Date(b.create_time).getTime() - new Date(a.create_time).getTime();
          });

          if (!cursor && _this.notificationNum.reply > 0) {
            readMessage({
              cursor: tempData[0].id,
              type: '1'
            }).then((result) => {
              const { data } = result;

              if (data.code === 200) {
                _this.$store.dispatch('getNotificationNum');
              }
            });
          }

          _this.commentObject.cursor = data.result.cursor;
          _this.commentObject.isEnd = data.result.is_end;
          _this.commentObject.list.push(...tempData);

          _this.commentObject.scrollFlag = false;
        } else {
          _this.msgError(data.message);
        }
      });
    },
    // 获取 话题 / 文章 详情
    getDetail(id) {
      let _this = this;

      _this.talkObject[id] = {};

      getTopicData({ uk: id }).then((result) => {
        if (Number(result.status) === 200) {
          _this.talkObject[id] = result.zhuanti;
        } else if (Number(result.status) === 404) {
          _this.talkObject[id] = 404;
        }
      });
    },
    // 打开话题页
    openTalk(talk_id) {
      let routeUrl = this.$router.resolve({
        path: `/talk/${talk_id}`
      });

      window.open(routeUrl.href, '_blank');
    },
    // 滚动事件
    scrollEvent() {
      let _this = this;

      let WH = window.innerHeight;
      let ST = document.documentElement.scrollTop;
      let commentWrapHeight = _this.$refs.commentWrap.offsetHeight;

      if (WH + ST + 100 >= 217 + commentWrapHeight && !_this.commentObject.isEnd && !_this.commentObject.scrollFlag) {
        _this.commentObject.scrollFlag = true;

        _this.getReplyList();
      }
    },
    // 页面点击事件
    clickEvent(event) {
      let falg = false;

      [].forEach.call(event.path, (element, index) => {
        if (index < event.path.length - 4 && element.classList.contains('comment-textarea-wrap')) {
          falg = true;
        }
      });

      if (this.replyObject.index !== '' && !falg) {
        this.commentObject.list[this.replyObject.index].replyEmojiIsShow = false;
      }
    },
    // emoji 点击事件
    commentEmojiClick(emoji) {
      let dom = this.$refs.commentTextarea[0].$el.firstElementChild;

      if (document.selection) {
        dom.focus();
        let sel = document.selection.createRange();
        sel.text = emoji;
      } else if (typeof dom.selectionStart === 'number' && typeof dom.selectionEnd === 'number') {
        let startPos = dom.selectionStart;
        let endPos = dom.selectionEnd;
        let cursorPos = startPos;
        let tmpStr = dom.value;
        dom.value = tmpStr.substring(0, startPos) + emoji + tmpStr.substring(endPos, tmpStr.length);
        cursorPos += emoji.length;
        dom.selectionStart = dom.selectionEnd = cursorPos;
      } else {
        this.replyObject.content += emoji;
      }

      dom.focus();
    },
    // 评论回复按钮
    replyBtn(commentInfo) {
      this.replyObject = {
        index: commentInfo.index,
        talk_id: commentInfo.talk_id,
        comment_id: commentInfo.comment_id,
        placeholder: '说两句吧...',
        content: ''
      };

      this.commentEmojiIsShow = false;

      this.commentObject.list.forEach((item, index) => {
        item.replyEmojiIsShow = commentInfo.index == index;
      });
    },
    // 回复框 回复按钮 调用的方法
    replyRequest() {
      let _this = this;

      try {
        _this.replyObject.content = _this.$refs.commentTextarea[0].$el.firstElementChild.value;
      } catch (error) {
        console.log(error);
      }

      if (!_this.replyObject.content) {
        _this.msgError('请输入回复内容');

        return;
      }

      let { index, talk_id, comment_id, content } = _this.replyObject;

      axiosAddComment({ talk_id, comment_id, content: content.replace(/&nbsp;/gi, ' ').trim() }).then((result) => {
        const { data } = result;

        if (data.code === 200) {
          // 关闭回复框
          _this.commentObject.list[index].replyEmojiIsShow = false;

          if (Number(data.result.examineStatus) === 1) {
            _this.msgSuccess('评论发布成功！');
          } else {
            _this.msgSuccess('评论发布成功，正在审核中，可能不会显示在评论列表中！');
          }

          if (data.code === 200) {
            _this.$store.dispatch('getNotificationNum');
          }

          _this.replyInputObject = {
            comment_id: '',
            placeholder: '请发表一些评论吧！',
            content: ''
          };
        } else {
          _this.msgError(data.message);
        }
      });
    }
  },
  created() {},
  mounted() {
    this.scrollEvent();

    window.addEventListener('scroll', this.scrollEvent);
    document.documentElement.addEventListener('click', this.clickEvent);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollEvent);
    document.documentElement.removeEventListener('click', this.clickEvent);
  }
};
</script>

<style lang="scss" scoped>
.comment-wrap {
  padding: 20px;

  .comment-item {
    position: relative;
    margin-bottom: 30px;

    .item-left {
      float: left;
      width: 40px;

      .photo-wrap {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #eee;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }

    .item-right {
      padding-left: 5px;
      overflow: hidden;

      .item-right-up {
        position: relative;

        .name {
          font-size: 14px;
          line-height: 24px;
          font-weight: bold;
        }

        .date {
          color: #999999;
          font-size: 12px;
          line-height: 24px;
        }

        .reply {
          color: #333333;
          font-size: 14px;
          line-height: 24px;
          word-break: break-all;

          .reply-first {
            height: 24px;
            display: inline-block;
          }

          .myself {
            display: inline-block;
            height: 24px;
            color: #1985ff;
          }

          /deep/ img {
            width: 24px;
            height: 24px;
            vertical-align: top;
          }
        }

        .reply-btn {
          position: absolute;
          top: 10px;
          right: 0;
          width: 72px;
          height: 26px;
          text-align: center;
          cursor: pointer;
          border: 1px solid #cbd0d5;
          border-radius: 14px;

          i {
            display: inline-block;
            margin-right: 5px;
            width: 14px;
            height: 12px;
            vertical-align: middle;
            background: url('../../assets/image/reply.png') center center no-repeat;
          }

          span {
            display: inline-block;
            color: #c4c4c4;
            font-size: 12px;
            line-height: 26px;
            vertical-align: middle;
          }

          &:hover,
          &.active {
            border: 1px solid #1985ff;

            i {
              background: url('../../assets/image/reply-coloured.png') center center no-repeat;
            }

            span {
              color: #1985ff;
            }
          }
        }
      }

      .item-right-down {
        margin-top: 5px;
        padding: 15px;
        background-color: #f5f6f7;

        .down-left {
          float: left;
          width: 40px;

          .down-photo-wrap {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid #eee;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }

        .down-right {
          padding-left: 5px;
          overflow: hidden;

          .down-right-up {
            .name {
              padding-top: 5px;
              color: #333333;
              font-size: 14px;
              line-height: 24px;
              font-weight: bold;
            }

            .reply {
              color: #666666;
              font-size: 14px;
              line-height: 24px;
              word-break: break-all;

              /deep/ img {
                width: 24px;
                height: 24px;
                vertical-align: top;
              }
            }
          }

          .down-right-down {
            margin-top: 5px;
            padding: 10px;
            background-color: #fff;

            .down-photo-wrap {
              float: left;
              width: 88px;
              height: 64px;

              img {
                display: block;
                width: 100%;
                height: 100%;
              }
            }

            .content {
              padding-left: 10px;
              height: 64px;
              cursor: pointer;
              overflow: hidden;

              .title {
                color: #333333;
                font-size: 14px;
                font-weight: bold;
              }

              .text {
                color: #666666;
                font-size: 12px;
                line-height: 22px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
            }

            &.no-talk {
              .down-photo-wrap {
                background-color: #eee;

                img {
                  display: none;
                }
              }

              .content {
                .title {
                  width: 200px;
                  height: 19px;
                  background-color: #eee;
                }

                .text {
                  margin-top: 4px;
                  width: 100%;
                  height: 40px;
                  background-color: #eee;
                }
              }
            }
          }
        }
      }

      .item-404 {
        margin-top: 5px;
        height: 54px;
        font-size: 0;
        text-align: center;
        background-color: #f5f6f7;

        .icon {
          display: inline-block;
          margin-top: 18px;
          margin-right: 10px;
          width: 18px;
          height: 18px;
          vertical-align: top;
          background: url('../../assets/image/item-404.png') center no-repeat;
        }

        .text {
          display: inline-block;
          height: 54px;
          line-height: 54px;
          color: #666666;
          font-size: 14px;
        }
      }
    }

    .comment-textarea-wrap {
      position: absolute;
      top: 50px;
      right: 10px;
      padding: 20px;
      width: 510px;
      background-color: #ffffff;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      box-sizing: border-box;
      z-index: 10;

      .close-btn {
        position: absolute;
        top: 3px;
        right: 3px;
        width: 18px;
        height: 18px;
        background: url('../../assets/image/notification_close.png') center no-repeat;
        cursor: pointer;
      }

      /deep/ .comment-textarea {
        font-size: 0;

        .el-textarea__inner {
          width: 100%;
          height: 80px;
          font-size: 14px;
          font-family: 'Microsoft YaHei', arial, sans-serif;
          background-color: #f5f5f6;
          border: none;
          resize: none;
        }
      }

      .textarea-bar {
        margin-top: 10px;
        height: 30px;

        .emoji-btn {
          float: left;
          padding: 5px 0;
          font-size: 0;
          cursor: pointer;

          span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
          }

          .emoji-icon-png {
            margin-right: 5px;
            width: 20px;
            background: url('../../assets/image/emoji-icon.png') center center no-repeat;
            vertical-align: top;
          }

          .emoji-text {
            font-size: 14px;
            color: #666666;
          }

          &.active,
          &:hover {
            .emoji-icon-png {
              background: url('../../assets/image/emoji-icon-coloured.png') center center no-repeat;
            }

            .emoji-text {
              color: #1985ff;
            }
          }
        }

        .reply-btn {
          float: right;
          font-size: 0;

          .el-button {
            padding: 7px 20px;
          }
        }
      }

      .emoji-icon {
        padding-top: 10px;
        width: 100%;

        .emoji-icon-list {
          width: 100%;
          height: 102px;
          font-size: 0;
          overflow-y: auto;

          &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 7px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 4px;
            background-color: #f2f2f2;
          }

          &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 7px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: rgba(0, 0, 0, 0.2);
            background-color: #1985ff;
          }

          &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            box-shadow: inset 0 0 5px #f2f2f2;
            border-radius: 0;
            background: #f2f2f2;
          }

          .emoji-icon-item {
            float: left;
            font-size: 0;
            cursor: pointer;

            img {
              display: block;
              padding: 5px;
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  .not-comment {
    width: 100%;
    height: 100%;

    img {
      display: block;
      margin: 150px auto 0;
    }
  }
}
</style>
